.footer-section{
    display: flex;
    justify-content: center;
    background-color: #404040;
    
}

.footer-links{
    display: flex;
    flex-direction: row;
    grid-gap:50px;
    list-style: none;
	
}

.footer-links li{
	color:#979797;
	font-size:.813em;
	margin:0 10px;
}
.footer-links li a{
	color:#979797;
    text-decoration: none;
}
.footer-navigation li a:hover,
.social-list li a:hover,
.footer-links li a:hover{
	color:#B753F9;
    transition: 0.5s ease;
}

/* @v120 -s */
.theme-pro .footer-section{
	background:#fff;
}
.theme-pro .footer-navigation li a,
.theme-pro .social-list li a,
.theme-pro .footer-links li,
.theme-pro .footer-links li a{
	color:#333;
}

.localization-menu{
    color:#979797;
    background: none;
    border: none;
}

/* @v120 -e */
@media only screen and (max-width: 767px) {
    
	.footer-navigation li{
		margin:0 7px;
		font-size:.81em;
	}
	.social-list li{
		font-size:1.1em;
		margin:0 7px;
	}
	.footer-links li{
		font-size:.7em;
		margin:0 7px;
	}
}